import React, { useEffect } from 'react';
import { Paper, Text, Col, Container, Grid } from '@mantine/core';
import './bio.scss';
import json from '../../content/content.json'
import SEO from '../SEO/SEO.jsx';
import ReactGA from 'react-ga4';

export const Bio = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_MID);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <>
      <SEO 
        title="Ethan Luxton"
        description="My bio."
        keywords="home, Ethan Luxton, blog, website, portfolio, bio"
        author="Ethan Luxton"
        imageUrl="../../assets/logo.png"
        pageUrl="https://ethanluxton.com/bio"
      />
      <h1 className="title-bio animation">About Me</h1>
      <Paper className="bioPage animation" padding="md">
        <Container>
          <Grid gutter="lg">
            <Col position="center">
              <Text className="bioText" size="sm">
                {json.about.bio}
              </Text>
              <br />
              <Text className="bioText" size="sm">
                {json.about.bio2}
              </Text>
              <br />
              <Text className="bioText" size="sm">
                {json.about.bio3}
              </Text>
              <br />
              <Text className="bioText" size="sm">
                {json.about.bio4}
              </Text>
              <br />
            </Col>
          </Grid>
        </Container>
      </Paper>
    </>
  );
};

