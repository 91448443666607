import { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import client from "../../client";
import BlockContent from "@sanity/block-content-to-react";
import { format } from "date-fns";
import "./SingleBlogPost.scss";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy } from '@fortawesome/free-solid-svg-icons';
import SEO from '../SEO/SEO.jsx';
import { SocialIcon } from 'react-social-icons'
import ReactGA from 'react-ga4';

export const SingleBlogPost = () => {
  
  const [singlePost, setSinglePost] = useState(null);
  const { slug } = useParams();
  const [copyTooltip, setCopyTooltip] = useState('Copy to clipboard');
  ReactGA.initialize(process.env.REACT_APP_GA_MID);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const handleSocialClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Shared a post'
    });
  };
  const copyText = () => {
    const input = document.getElementById("text");
    input.select();
    document.execCommand("copy");
    setCopyTooltip('Copied!');
    setTimeout(() => {
      setCopyTooltip('Copy to clipboard');
    }, 2000);
  }
  
  useEffect(() => {
    client
      .fetch(
        `*[slug.current == "${slug}"] {
          title,
          slug,
          body,
          publishedAt,
          mainImage {
            asset -> {
              _id,
              url
            },
            alt,
          },
          "name": author->name,
        }`
      )
      .then((data) => setSinglePost(data[0]))
      .catch(console.error);
  }, [slug]);

  if (!singlePost) {
    return <div>Loading...</div>;
  }
  const social = [
    <SocialIcon onClick={handleSocialClick()} url={`https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(window.location.href)}`} />,
    <SocialIcon onClick={handleSocialClick()} url={`https://twitter.com/intent/tweet?url=${encodeURIComponent(window.location.href)}&text=${encodeURIComponent(singlePost.title)}`}/>,
    <SocialIcon onClick={handleSocialClick()} url={`https://www.pinterest.com/pin/create/button/?url=${encodeURIComponent(window.location.href)}&media=${encodeURIComponent(singlePost.mainImage.asset.url)}&description=${encodeURIComponent(singlePost.title)}`} />,
    <SocialIcon onClick={handleSocialClick()} url={`https://www.linkedin.com/shareArticle?url=${encodeURIComponent(window.location.href)}&title=${encodeURIComponent(singlePost.title)}`} />,
    <SocialIcon onClick={handleSocialClick()} url={`mailto:?subject=${encodeURIComponent(singlePost.title)}&body=${encodeURIComponent(window.location.href)}`} />,
  ];

  return (
    <div className="single-post-container">
      <SEO 
        title="Ethan Luxton's Blog"
        description={`${singlePost.title}`}
        keywords="blog, Ethan Luxton, blog post"
        author="Ethan Luxton"
        imageUrl="../../assets/logo.png"
        pageUrl="https://ethanluxton.com/blog-post/:slug"
      />
      <section className="single-post animation">
        <h1 className="post-title">{singlePost.title}</h1>
        {singlePost.mainImage && singlePost.mainImage.asset && (
          <div className="image-container">
            <img
              src={singlePost.mainImage.asset.url}
              alt={singlePost.title}
              className="post-image"
            />
          </div>
        )}
        <div className="post-meta">
          {singlePost.publishedAt && (
            <small>
              Published by {singlePost.name} on{" "}
              {format(new Date(singlePost.publishedAt), "dd MMMM yyyy")}
            </small>
          )}
        </div>
        <div className="post-content">
          <BlockContent
            blocks={singlePost.body}
            projectId="9t4l8ums"
            dataset="production"
          />
        </div>
        <Link to="/blog" className="back-link">
          &larr; Back to Blog
        </Link>
      </section>
      <section>
        <div className="shareArticle">
          <div className="shareSocial">
            <h3 className="socialTitle">Share Post:</h3>
            <ul className="socialList">
              <li>{social[0]}</li>
              <li>{social[1]}</li>
              <li>{social[2]}</li>
              <li>{social[3]}</li>
              <li>{social[4]}</li>
      
            </ul>
          </div>
          <div className="shareLink">
            <div className="permalink">
              <input className="textLink" id="text" type="text" name="shortlink" value={window.location.href} readOnly />
              <span className="copyLink" id="copy" onClick={copyText} title={copyTooltip}>
                <FontAwesomeIcon icon={faCopy} />
              </span>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default SingleBlogPost;
