import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import client from "../../client";
import { format } from "date-fns";
import "./blog.scss"; 
import SEO from '../SEO/SEO.jsx';
import ReactGA from 'react-ga4';

export const Blog = () => {
  ReactGA.initialize(process.env.REACT_APP_GA_MID);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const [posts, setPosts] = useState([]);
  const handlePostClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Read a blog post'
    });
  };
  useEffect(() => {
    client
      .fetch(`*[_type == "post"] {
        title,
        slug,
        body,
        publishedAt,
        mainImage {
          asset -> {
            _id,
            url
          },
          alt,
        }
      } | order(publishedAt desc)`)
      .then((data) => setPosts(data))
      .catch(console.error);
  }, []);
  
  return (
    <>
      <SEO 
        title="Ethan Luxton's Blog"
        description="A collection of blog posts about travel, 
        finance and technology"
        keywords="personal finance, 
        technology, 
        geopolitics, 
        finance management, 
        investment strategies, 
        fintech innovations, 
        global economics, 
        political impact, 
        financial technology, 
        economic trends"
        author="Ethan Luxton"
        imageUrl="../../assets/logo.png"
        pageUrl="https://ethanluxton.com/blog"
      />
      <div className="Title animation">
        <h1 >My blog</h1>
        <h4>Here I write about the things that interest me on a daily basis. Everything from travel, to finance, to technology.</h4> 
      </div>
      <section className="blog animation">
        {posts.length > 0 ? (
          posts.map((post, index) => (
            <article className="post" key={index}>
              {post.mainImage && post.mainImage.asset && (
                <img src={post.mainImage.asset.url} alt={post.title} />
              )}
              <div>
                <h2>{post.title}</h2>
                {post.publishedAt && (
                  <small>Published On: {format(new Date(post.publishedAt), "dd MMMM yyyy")}</small>
                )}
                <p>{`${post.body[0].children[0].text.substring(0, 100)}...`} </p>
                <Link onClick={handlePostClick()} to={`/blog-post/${post.slug.current}`}>Read full article</Link>
              </div>
            </article>
          ))
        ) : (
          <div className="no-posts-message">
            <h3>No posts yet, please check back later.</h3>
          </div>
        )}
      </section>
    </>
  );
};

