import { Container, Group } from '@mantine/core';
import './footer.scss';
import ReactGA from 'react-ga4';

export function FooterSocial() {
  ReactGA.initialize(process.env.REACT_APP_GA_MID);
  const handleGHClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Shared a post'
    });
  };
  return (
    <div className="footer animation">
      <Container className="inner">
        <Group>
          <p className="text">
            &copy; 2024 |&nbsp;
            <a href="https://github.com/ethan-luxton/portfolio-2023" 
            target="_blank" 
            rel="noreferrer" 
            className="text sourcelink" 
            aria-label="View source on GitHub"
            onClick={handleGHClick}
            >
              Page Source
            </a>
          </p>
        </Group>
      </Container>
    </div>
  );
}
