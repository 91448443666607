import json from '../../content/content.json';
import React, { useState, useEffect } from 'react';
import './portfolio.scss';
import Modal from 'react-bootstrap/Modal';
import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import SEO from '../SEO/SEO.jsx';
import ReactGA from 'react-ga4';
let projectData = json.about.projects;
function ProjectModal({ project }) {
  ReactGA.initialize(process.env.REACT_APP_GA_MID);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const [show, setShow] = useState(false);
  const handleProjClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Shared a post'
    });
  };
  const handleClose = () => setShow(false);
  const handleShow = () => {setShow(true); handleProjClick();};
  
  return (
    <>
      <SEO 
        title="Portfolio - Ethan Luxton"
        description="Ethan Luxton's Portfolio"
        keywords="Ethan Luxton, portfolio"
        author="Ethan Luxton"
        imageUrl="../../assets/logo.png"
        pageUrl="https://ethanluxton.com/portfolio"
      />
      <Button className="custom-button" onClick={handleShow}>
        View more
      </Button>
    
      <Modal show={show} onHide={handleClose} dialogClassName="custom-modal">
        <Modal.Header>
          <Modal.Title>{project.title}</Modal.Title>
          <Button
            variant="secondary"
            className="custom-button"
            onClick={handleClose}
          >
            Close
          </Button>
        </Modal.Header>
        <Modal.Body>
          <p>{project.startDate}</p>
          <p>{project.description}</p>
          <div className="modal-content-url">
            <a href={project.url[0]} target="_blank" rel="noreferrer" className="text">
              {project.url[1]}
            </a>

            <span class="line -bottom"></span>
          </div>

          <div className="skillsContainer">
            <ul className="iconHolder">
              {project.technologies.map((tech) => (
                <li className="skillsList" key={tech.name}>
                  <i className={tech.class} id="skills">
                    <p className="skill">{tech.name}</p>
                  </i>
                </li>
              ))}
            </ul>
          </div>
          <div className='img-div'>
            {project.images.map((img, index) => (
              <img
                key={index}
                src={img}
                alt={`${project.title} screenshot ${index}`}
                className="img-thumbnail"
              />
            ))}
          </div>
          
        </Modal.Body>
      </Modal>
    </>
  );
}

const Portfolio = () => {

  return (
    
    <Container>
      
      <Container className="mt-5 animation">
        <h1 className="animation">Projects</h1>
        <Row>
          {projectData.map((project, index) => (
            <Col key={index} md={4} className="mb-4">
              <Card className="h-100">
                <Card.Body>
                  <Card.Title>{project.title}</Card.Title>
                  <Card.Text>{project.description}</Card.Text>
                  <Card.Img variant="top" src={project.images[0]} />
                </Card.Body>
                <Card.Footer>
                  <ProjectModal project={project} />
                </Card.Footer>
              </Card>
            </Col>
          ))}
        </Row>
      </Container>
      
      
    </Container>
  );
};

export default Portfolio;
