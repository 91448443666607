import './contact.scss';
import { useNavigate } from 'react-router-dom';
import {
    Button,
  } from '@mantine/core';
export const SubConfirm = () => {
    const navigate = useNavigate();
    const returnHome = () => {
        navigate('/')
    }

    return (
        <div className="thank-you-page animation">
            <h2>Thank you for your note!</h2>
            <p>I will aim to respond as soon as possible!</p>
            <Button onClick={returnHome}>Return home</Button>
        </div>
    )
}

