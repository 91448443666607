import React from 'react';
import { useNavigate } from 'react-router-dom';
import emailjs from '@emailjs/browser';
import {
  
  SimpleGrid,
  TextInput,
  Textarea,
  Button,
  Group,
} from '@mantine/core';
import { SocialIcon } from 'react-social-icons'
import './contact.scss';
import SEO from '../SEO/SEO.jsx';
import ReactGA from 'react-ga4';
import { useEffect } from 'react';
const social = [
  <SocialIcon url="https://www.x.com/eth_luxton"/>,
  <SocialIcon url="https://www.instagram.com/ethanluxphotography/?hl=en"/>,
  <SocialIcon url="https://www.facebook.com/profile.php?id=61560367675706"/>,
  <SocialIcon url="https://github.com/ethan-luxton"/>,
  <SocialIcon url="https://www.linkedin.com/in/ethan-luxton/"/>,
];

export function ContactUs() {
  ReactGA.initialize(process.env.REACT_APP_GA_MID);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  const handleButtonClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked a button'
    });
  };
  const navigate = useNavigate();

  const sendEmail = (e) => {
    e.preventDefault();
    emailjs
      .sendForm(
        process.env.REACT_APP_SERVICE_KEY,
        process.env.REACT_APP_EMAIL_JS_TEMPLATE,
        e.target,
        {
          publicKey: process.env.REACT_APP_EMAIL_JS_KEY,
        }
      )
      .then(
        () => {
          console.log('SUCCESS!');
          navigate('/submission-confirmed');
        },
        (error) => {
          console.log('FAILED...', error);
        }
      );
  };

  return (
    <>
    <h1 className='animation'>Let's talk</h1>
    <div className='wrapper animation'>
      <SEO 
        title="Contact - Ethan Luxton"
        description="Contact me!"
        keywords="contact, Ethan Luxton"
        author="Ethan Luxton"
        imageUrl="../../assets/logo.png"
        pageUrl="https://ethanluxton.com/contact"
      />
      
      <SimpleGrid className='grid animation' cols={{ base: 1, sm: 1 }} spacing={50}>
        <form className='form animation' onSubmit={sendEmail}>
          <TextInput
            label="Email"
            name="from_email"
            placeholder="your@email.com"
            required
            className='input'
          />
          <TextInput
            label="Name"
            name="from_name"
            placeholder="Your name"
            mt="md"
            required
            className='input'
          />
          <Textarea
            required
            label="Message"
            name="message"
            placeholder="Your message"
            minRows={4}
            mt="md"
            className='input'
          />
          <Group justify="flex-end" mt="md" className='btngroup'>
            <Button type="submit" className='control' onClick={handleButtonClick}>Send message</Button>
          </Group>
        </form>
      </SimpleGrid>
      <Group mt="xl" className='social-icons animation'>{social}</Group>
    </div>
    </>
  );
}
