import './App.scss';
import Header from './components/Header/Header.jsx';
import { FooterSocial } from './components/Footer/Footer.jsx';
import { HomePage } from './components/About/AboutMe.jsx';
import { ContactUs } from './components/Contact/Contact.jsx';
import { SubConfirm } from './components/Contact/SubConfirm.jsx';
import { Blog } from './components/Blog/Blog.jsx'
import { SingleBlogPost } from './components/Blog/SingleBlogPost.jsx'
import { Bio } from './components/Bio/Bio.jsx'
import { Route, Routes } from 'react-router-dom';
import Portfolio from './components/Portfolio/Portfolio.jsx';

import { createStyles, useMantineTheme } from '@mantine/core';

const App = () => { 
  const useStyles = createStyles((theme) => ({
    pageWrapper: {
      display: 'flex',
      flexDirection: 'column',
      minHeight: '100vh',
      position: 'relative',
    },
  }));
  const theme = useMantineTheme();
  const { classes } = useStyles({ theme });
  return (
    <div className={classes.pageWrapper}>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<ContactUs />} />
        <Route path="/blog" element={<Blog/>}/>
        <Route path="/blog-post/:slug" element={<SingleBlogPost/>}/>
        <Route path="/portfolio" element={<Portfolio />} />
        <Route path="/bio" element={<Bio/>}/>
        <Route path="/submission-confirmed" element={<SubConfirm />} />    
      </Routes>
      <FooterSocial />
    </div>
  );
};

const Home = () => {
  return <HomePage />;
};

export default App;
