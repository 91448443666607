import React, { useState } from 'react';
import './header.scss';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom';
import ReactGA from 'react-ga4';

const Header = () => {
  const [expanded, setExpanded] = useState(false);
  ReactGA.initialize(process.env.REACT_APP_GA_MID);
  const handleLinkClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Navigated to different page'
    });
  };

  return (
    <header className="header animation">
      <Navbar expanded={expanded} className="Nav" expand="lg">
        <Container className='links'>
          <Navbar.Brand
            onClick={() => { setExpanded(false); handleLinkClick(); }}
            as={Link}
            to="/"
            className="home"
          >
            Home
          </Navbar.Brand>
          <Navbar.Toggle
            onClick={() => setExpanded(expanded ? false : 'expanded')}
            className="button"
            aria-controls="basic-navbar-nav"
          />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="me-auto">
              <Nav.Link
                onClick={() => { setExpanded(false); handleLinkClick(); }}
                as={Link}
                to="/bio"
                className="bio"
              >
                Bio
              </Nav.Link>
              <Nav.Link
                onClick={() => { setExpanded(false); handleLinkClick(); }}
                as={Link}
                to="/portfolio"
                className="portfolio"
              >
                Projects
              </Nav.Link>
              <Nav.Link
                onClick={() => { setExpanded(false); handleLinkClick(); }}
                as={Link}
                to="/contact"
                className="contact"
              >
                Contact
              </Nav.Link>
              <Nav.Link
                onClick={() => { setExpanded(false); handleLinkClick(); }}
                as={Link}
                to="/blog"
                className="blog-page-button"
              >
                Blog
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    </header>
  );
};

export default Header;
