import SEO from '../SEO/SEO.jsx';
import './about.scss';
import BackgroundSlider from 'react-background-slider'
import img1 from '../../assets/bgimage1.jpg'
import img2 from '../../assets/bgimage2.jpg'
import img3 from '../../assets/bgimage3.jpg'
import img4 from '../../assets/bgimage4.jpg'
import img5 from '../../assets/bgimage5.jpg'
import img6 from '../../assets/bgimage6.jpg'
import ReactGA from 'react-ga4';
import { useEffect } from "react";
export function HomePage() {
  ReactGA.initialize(process.env.REACT_APP_GA_MID);
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    
    <>
      <SEO 
        title="Ethan Luxton"
        description="My website."
        keywords="home, Ethan Luxton, blog, website, portfolio"
        author="Ethan Luxton"
        imageUrl="../../assets/logo.png"
        pageUrl="https://ethanluxton.com/"
      />
      <link rel="preconnect" href="https://fonts.googleapis.com"/>
      <link rel="preconnect" href="https://fonts.gstatic.com" crossorigin/>
      <link href="https://fonts.googleapis.com/css2?family=La+Belle+Aurore&display=swap" rel="stylesheet"/>
      <BackgroundSlider
        images={[img1, 
                img2, 
                img3, 
                img4,
                img5,
                img6
              ]}
        duration={7} transition={2} 
        />
      
      <div className='wrapper-home'>
        <h1 className="title-home animation">
          Ethan Luxton
        </h1>
      </div>
      
          
        
    </>
  );
}
