import createClient from "@sanity/client"


const client = createClient ({
    projectId: '9t4l8ums',
    dataset: "production",
    useCdn: true,
    apiVersion: "2022-03-08"
  })

export default client